import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import LegacyChart from '../graphs/LegacyChart'
import DerivativeChart from '../graphs/DerivativeChart'
import CommandsPie from '../graphs/CommandsPie';
import LanguagesChart from '../graphs/LanguagesChart';
import FailureChart from '../graphs/FailureChart';

export default function Stats() {
    return (
        <Grid container paddingTop={4} direction="column" justifyContent="center" alignItems="center" spacing={4} paddingBottom={10}>
            <Grid item xs={12}>
                <Paper  sx={{width:'70vw',backgroundColor: '#303030'}}>
                    <LegacyChart/>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper  sx={{width:'70vw',backgroundColor: '#303030'}}>
                    <DerivativeChart/>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper  sx={{width:'70vw',backgroundColor: '#303030'}}>
                    <CommandsPie/>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper  sx={{width:'70vw',backgroundColor: '#303030'}}>
                    <LanguagesChart/>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper  sx={{width:'70vw',backgroundColor: '#303030'}}>
                    <FailureChart />
                </Paper>
            </Grid>
        </Grid>
    );
}