import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import LegacyChart from '../graphs/LegacyChart'
import DerivativeChart from '../graphs/DerivativeChart'
import CommandsPie from '../graphs/CommandsPie';
import LanguagesChart from '../graphs/LanguagesChart';
import FailureChart from '../graphs/FailureChart';
import Example from "../resources/example.png";
import SendIcon from '@mui/icons-material/Send';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import {Link as RouterLink} from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, List, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GitHubIcon from '@mui/icons-material/GitHub';
export default function Tutorial() {
    return (
        <Grid container paddingTop={2} direction="row" justifyContent="center" alignItems="center" spacing={2} paddingBottom={10}>
            <Grid item xs={5} justifyContent='center'>
                <Paper sx={{justifyContent: 'center', alignItems:'center', backgroundColor:'#303030', padding:'4%', minWidth:'20vw'}}>
                    <Grid container paddingTop={2} direction="column" justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item xs={5} justifyContent='center'>
                            <Typography variant='h4' color={'#FFFFFF'}>
                                RTFM!
                            </Typography>
                            <br/>
                            <Typography variant='body1' color={'#FFFFFF'}>
                                Our technical explanations for the parsing of the ";compile" command is detailed on our GitHub wiki.
                                If you'd like read about all of the bot's possiblities, this is the best place to go.
                            </Typography>
                        </Grid>
                        <Grid item xs={7} justifyContent='center'>
                            <Button startIcon={<GitHubIcon/>} sx={{color:'#FFFFFF', backgroundColor:'#202020'}} href='https://github.com/Headline/discord-compiler-bot/wiki/1.-Getting-Started'>
                                GitHub Wiki
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={5} justifyContent='center'>
                <Paper sx={{justifyContent: 'center', alignItems:'center', backgroundColor:'#303030', padding:'2%', minWidth:'20vw'}}>
                    <Typography variant='h4' color={'#FFFFFF'}>
                        Request Examples
                    </Typography>
                    <br/>
                    <Typography variant='body' color={'#FFFFFF'}>
                        Here are some quick examples if you'd like to get started right away. For these we'll use c++.
                        <br/>
                        <br/>
                        &lt;coming soon&gt;
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    );
}