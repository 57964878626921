import * as React from 'react';

import Plot from 'react-plotly.js';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';


export default class LegacyChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: null
        };
    }

    prepData(json) {
	
        let requests = [];
        let servers = [];
        let dates = [];
        for (var i = 0; i < json.length; i++) {
            var data = json[i];
            
            requests.push(data.requests);
            servers.push(data.servers);
            dates.push(data.date);
        }
        
        let traces = [];
        
        traces.push({x: dates, y: requests, name: "Requests"});
        traces.push({x: dates, y: servers, name: "Servers", marker: {color: 'rgb(255,165,0)'}});
        
        return traces;
    }

    async componentDidMount() {
        const res = await fetch('https://compiler.gg:2096/retrieve/legacy');
        const data = await res.json();
        this.setState({chartData: data});
    }

    render() {
        if (!this.state.chartData) {
            return (<Grid container spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    minHeight={400}>
                        <Grid item>
                            <CircularProgress style={{'color': '#32c7fc'}} />    
                        </Grid>    
                    </Grid>)
        }

        const traces = this.prepData(this.state.chartData);
        const layout = {
            title: 'Servers and Requests', 
            showlegend: true,
            hovermode:"x",
            paper_bgcolor: '#303030',
            plot_bgcolor: '#303030',
            font: {
                color: '#FFFFFF'
            },
            yaxis: {
                gridcolor: '#595959'
            },
            xaxis: {
                gridcolor: '#595959',
                zeroline: 1,
                zerolinecolor: '#444'
            },
            margin: {
                t: 50, //top margin
                l: 50, //left margin
                r: 50, //right margin
                b: 30 //bottom margin
            },
        };
        return (
            <Plot useResizeHandler={true}
            style={{width: '70vw'}}
            data={traces}
            layout={layout}
            />
        );
    }
}