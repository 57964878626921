import React from "react";
import {Outlet} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

export default class Layout extends React.Component {
    render() {
        return (
            <>
                <header>
                    <Navbar />
                </header>
                <div className='content-wrap'>
                    <Outlet />
                </div>
                <Footer />
            </>
          );
    }
}
