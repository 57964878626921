import * as React from 'react';

import Plot from 'react-plotly.js';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';


export default class LanguagesChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: null
        };
    }

    prepData(json) {
        var languages = [];
        var count = [];
        
        for (var i = 0; i < json.length; i++) {
            var obj = json[i];
            languages.push(obj.language.charAt(0).toUpperCase() + obj.language.slice(1));
            count.push(obj.count + obj.failCount);
        }   
            
        var data = [{
            values: count,
            labels: languages,
            type: 'pie',
            textinfo: 'percent',
            textposition: 'inside'
        }];
        return data;
    }

    async componentDidMount() {
        const res = await fetch('https://compiler.gg:2096/retrieve/languages');
        const data = await res.json();
        this.setState({chartData: data});
    }

    render() {
        if (!this.state.chartData) {
            return (
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" minHeight={400}>
                <Grid item>
                    <CircularProgress style={{'color': '#32c7fc'}} />    
                </Grid>    
            </Grid>
            )
        }

        const traces = this.prepData(this.state.chartData);
        const layout = {
            title: 'Language Popularity', 
            showlegend: true, 
            paper_bgcolor: '#303030',
            font: {
                color: '#FFFFFF'
            },
            margin: {
                t: 50, //top margin
                l: 50, //left margin
                r: 50, //right margin
                b: 30 //bottom margin
            },
        };

        return (
            <Plot useResizeHandler={true}
            style={{width: '70vw'}}
            data={traces}
            layout={layout}
            />
        );
    }
}