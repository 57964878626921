import './App.css';
import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Stats from './pages/Stats';
import Layout from './Layout';
import Tutorial from './pages/Tutorial';

function App() {
  return (
  <div className="page-container">
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="stats" element={<Stats />} />
                    <Route path="tutorial" element={<Tutorial />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
