import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import LegacyChart from '../graphs/LegacyChart'
import DerivativeChart from '../graphs/DerivativeChart'
import CommandsPie from '../graphs/CommandsPie';
import LanguagesChart from '../graphs/LanguagesChart';
import FailureChart from '../graphs/FailureChart';
import Example from "../resources/example.png";
import SendIcon from '@mui/icons-material/Send';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import {Link as RouterLink} from "react-router-dom";

export default function Home() {
    return (
        <Grid container paddingTop={2} direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ flexDirection: { xs: "column", md: "row"}}}>
            <Grid item xs={5} justifyContent='center'>
                <Grid container direction='column' spacing={6} justifyContent="center" alignItems="center" minWidth='30vw'>
                    <Grid item xs={6}>
                        <Typography variant='h5' color='#FFFFFF' textAlign='center'>
                        A Discord bot to execute your spaghetti code.
                        </Typography>
                        <br/>
                        <Typography variant='body1' color='#FFFFFF' textAlign='center' bottom='0'>
                        Execute code, view assembly output, and more. Open-source & free forever.
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container direction='row' spacing={7} sx={{ flexDirection: { xs: "column", md: "row", justifyContent:'center'} }}>
                            <Grid item xs={6}>
                                <Button startIcon={<SendIcon/>} size='large' variant='contained' sx={{backgroundColor:'#93cccc', color:'#000000', minWidth:'max-content'}} onClick={() => {window.location.href = 'https://discord.com/oauth2/authorize?client_id=504095380166803466&permissions=379968&scope=applications.commands%20bot';}}>
                                    Invite to server
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button to='/tutorial' LinkComponent={RouterLink} startIcon={<LibraryBooksIcon/>} size='large' variant='contained' sx={{ minWidth: 'max-content'}}>
                                    Learn more
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} justifyContent='center' minWidth='30vw'>
                <Paper sx={{justifyContent: 'center', alignItems:'center', backgroundColor:'#303030', padding:'2%'}}>
                    <img src={Example} alt="Discord Compiler Logo" style={{width: '100%'}}/>
                </Paper>
            </Grid>
        </Grid>
    );
}