import * as React from 'react';

import Plot from 'react-plotly.js';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

function getPercent(fail, success) {
	return ((fail/(fail+success)) * 100)
}

export default class FailureChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: null
        };
    }

    prepData(json) {
        let languages = [];
        let count = [];
        
        json.sort((a, b) => {
            let ap = getPercent(a.failCount, a.count);
            var bp = getPercent(b.failCount, b.count);
            if (ap > bp) {
                return -1;
            }
            if (bp > ap) {
                return 1;
            }
            return 0;
        });
        
        for (let i = 0; i < json.length; i++) {
            const obj = json[i];
            const language = obj.language.charAt(0).toUpperCase() + obj.language.slice(1);
            const failCount = (obj.failCount != null)?obj.failCount:0;
    
            if (failCount != 0 && failCount + obj.count >= 10) {
                languages.push(language);
                const percent = getPercent(failCount, obj.count);
                count.push(percent.toFixed(2));
            }
        }
        
        const data = [{
            x: languages,
            y: count,
            text: count.map(x => x.toString() + "%"),
            textposition: 'auto',
            type: 'bar'
        }];
        return data;
    }

    async componentDidMount() {
        const res = await fetch('https://compiler.gg:2096/retrieve/languages');
        const data = await res.json();
        this.setState({chartData: data});
    }

    render() {
        if (!this.state.chartData) {
            return (<Grid container spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    minHeight={400}>
                        <Grid item>
                            <CircularProgress style={{'color': '#32c7fc'}} />    
                        </Grid>    
                    </Grid>)
        }

        const traces = this.prepData(this.state.chartData);

        const layout = {
            title: 'Percentage Of Compilation Failures By Language', 
            showlegend: false, 
            paper_bgcolor: '#303030',
            plot_bgcolor: '#303030',
            font: {
                color: '#FFFFFF'
            },
            hovermode: 'x',
            yaxis: {
                text: 'Failure Percentage',
                gridcolor: '#595959'
            },
            xaxis: {
                text: 'Languages',
                gridcolor: '#202020',
                zeroline: 1,
                zerolinecolor: '#444'
            },
            margin: {
                t: 50, //top margin
                l: 50, //left margin
                r: 50, //right margin
                b: 80 //bottom margin
            },
            yaxis: {
                range: [0, 100]
            },    
        };
        return (
            <Plot useResizeHandler={true}
            style={{width: '70vw'}}
            data={traces}
            layout={layout}
            />
        );
    }
}