import * as React from 'react';

import Plot from 'react-plotly.js';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';


export default class DerivativeChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: null
        };
    }

    prepData(json) {
        var servers = [];
        var dates = [];
        for (var i = 0; i < json.length-1; i+=1) {
            var d1 = json[i];
            var d2 = json[i+1];
            
            servers.push(d2.servers - d1.servers);
            dates.push(d2.date);
        }
        
        var traces = [];
        traces.push({x: dates, y: servers, name: "Servers",  marker: {color: 'rgb(31,119,180)'}});
    
    
        var moveMean = [];
        
        //calculate moving average
        var n = 5;
        for (var i = 0; i < n; i++) {
            var mean = servers.slice(0, i+1).reduce(function(a, b) { return a+b;}, 0)/((i+1)*1.0);
            moveMean.push(mean);
        }
        for (var i = n; i < servers.length; i++)
        {
            var mean = servers.slice(i-n, i+1).reduce(function(a, b) { return a+b;}, 0)/(n*1.0);
            moveMean.push(mean);
        }
        traces.push({x: dates, y: moveMean, name: "Moving Avg", marker: {color: 'rgb(255,165,0)'}});            
        return traces;
    }

    async componentDidMount() {
        const res = await fetch('https://compiler.gg:2096/retrieve/legacy');
        const data = await res.json();
        this.setState({chartData: data});
    }

    render() {
        if (!this.state.chartData) {
            return (<Grid container spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    minHeight={400}>
                        <Grid item>
                            <CircularProgress style={{'color': '#32c7fc'}} />    
                        </Grid>    
                    </Grid>)
        }

        const traces = this.prepData(this.state.chartData);

        const layout = {
            title: 'Daily Server Growth', 
            showlegend: true, 
            paper_bgcolor: '#303030',
            plot_bgcolor: '#303030',
            font: {
                color: '#FFFFFF'
            },
            hovermode: 'x',
            yaxis: {
                gridcolor: '#595959'
            },
            xaxis: {
                gridcolor: '#595959',
                zeroline: 1,
                zerolinecolor: '#444'
            },
            margin: {
                t: 50, //top margin
                l: 50, //left margin
                r: 50, //right margin
                b: 30 //bottom margin
            },
        };
        return (
            <Plot useResizeHandler={true}
            style={{width: '70vw'}}
            data={traces}
            layout={layout}
            />
        );
    }
}